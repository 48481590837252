<template>
  <div v-if="!$route.fullPath.includes('profile')">
    <div class="block__subtitle mb-40">{{ title }}</div>
    <div class="block__tabs mb-30">
      <VTabs :is-small="true" :items="adminUsers" v-if="!$route.fullPath.includes('profile')" />
    </div>
    <div class="block__control">
      <div class="block__field">
        <VSearch
          name="searchUsersAdmin"
          placeholder="Поиск"
          class-input="input-border-all input-padding-25 input-height-48"
          v-model="input"
        />

        <VFilter
          @onFilter="onSubmit"
          @onReset="onReset"
          v-if="!$route.path.includes('journal')"
        >
          <template v-slot:default>
            <VDateInput
              class="mb-40"
              placeholder="Выберите период"
              v-model="dateRange"
            />
          </template>
        </VFilter>
      </div>

      <div class="admin__button" v-if="!$route.fullPath.includes('journal')">
        <VButton mod="plus" @click="toCreateUser">Добавить</VButton>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import VSearch from '@/components/ui/form/Search/VSearch'
import VDateInput from '@/components/ui/form/VDateInput'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VButton from '@/components/ui/buttons/VButton'
import VTabs from '@/components/views/Tabs/VTabs'
import { adminUsers } from '@/utils/tabs'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useFilter } from '@/use/Base/useFilter'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const router = useRouter()
    const dateRange = ref(null)

    const toCreateUser = () => {
      return router.push({ name: 'admin-users-create' })
    }

    const filter = () => {
      if (!dateRange.value) return
      const role = router.currentRoute.value.path.split('/').at(-1)
      const date = dateRange.value.toISOString().split('T')
      return [{ type: 'CreateDate', role, value: date[0] }]
    }

    const reset = () => {
      dateRange.value = null
    }

    return {
      dateRange,
      adminUsers,
      toCreateUser,
      ...useFilter(filter, reset)
    }
  },
  components: {
    VButton,
    VSearch,
    VTabs,
    VFilter,
    VDateInput
  }
}
</script>
